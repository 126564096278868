import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PopupRef } from '@progress/kendo-angular-popup';
import { lastValueFrom, map, Observable } from 'rxjs';
import { API_URL } from 'src/app/constants/url';
import { ConferenceListItem } from 'src/app/layout/constants/interface/interface';
import { HttpService } from 'src/app/services/http/http.service';
import { PopupService } from 'src/app/services/popup';

@Injectable()
export class DashboardService {

  private popupRef: PopupRef | null = null;
  private conferenceCardDetail!: ConferenceListItem

  constructor(private _http: HttpService, private _popup: PopupService, private $http: HttpClient) { }

  getConferences(data: any): Observable<any[]> {
    return this._http.post(API_URL.conferencesList, data).pipe(map(response => {
      const data = response as unknown as any[];
      return data; 
    }))
  }

  onLikeDislike(data:any) {
    return lastValueFrom(this._http.get(API_URL.likeDislike, data, false, 'text'))
  }

  generateItinerary(data: any, id: string)  {
    return this.$http.post(API_URL.generateItinerary(id), data, {responseType: 'arraybuffer'})
     
  }

  public setPopupRef(popupRef: PopupRef): void {
    this.popupRef = popupRef;
  }

  public getPopupRef(): PopupRef | null {
    return this.popupRef;
  }

  public closePopup(): void {
    if (this.popupRef) {
      this.popupRef.close();
      this.popupRef = null;
    }
  }

  setConferenceCardData(data: ConferenceListItem) {
     this.conferenceCardDetail = data
  }

  getConferenceCardData() {
    return this.conferenceCardDetail;
  }

}

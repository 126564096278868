export namespace HomeAction {
    export class GetHomeEventData {
        static readonly type = "[Home] Get home event data";
        constructor(public readonly conferenceId: string) { }
    }

    export class ShowHeader {
        static readonly type = "[Home] Show header";
        constructor(public readonly show: boolean) { }
    }
}

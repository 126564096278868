export namespace AuthAction {
    export class Login {
        static readonly type = '[Auth] Login';
        constructor(public readonly data: any) {}
    }

    export class ValidateOtp {
        static readonly type = '[Auth] Validate Otp';
        constructor(public readonly data: any) {}
    }

    export class AuthGuardHandler {
        static readonly type = '[Auth] Auth Guard handler';
    } 
    export class ListenHeading {
        static readonly type = '[Auth] Listen heading';
        constructor(public heading: string) { }
    }

    export class DetectLoginPage {
        static readonly type = '[Auth] Detect login page'
        constructor(public isLoginPage: boolean) {}
    }

    export class ResetProfile {
        static readonly type = '[Auth] Reset profile data'
    }

    export class HideShowHomeIconAction {
        static readonly type = '[Auth] Hide Show Home icon';
        constructor(public readonly data: boolean) { }
    }
    
}
